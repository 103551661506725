import React, { useRef, useEffect } from "react"
import { gsap } from "gsap"
import style from "./MarqueeText.module.scss"

type MarqueeTextProps = {
  text: string
}

const MarqueeText: React.FC<MarqueeTextProps> = ({ text }) => {
  const quote = useRef<HTMLHeadingElement>(null)
  useEffect(() => {
    gsap
      .timeline({ repeat: -1 })
      .to(quote.current, { duration: 60, xPercent: -50, ease: "none" })
      .set(quote.current, { xPercent: 0 })
  }, [])

  return (
    <div className={style.container}>
      <blockquote ref={quote}>
        {text} {text} {text} {text}{" "}
      </blockquote>
    </div>
  )
}

export default MarqueeText
