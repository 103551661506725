import React from "react"
import style from "./Button.module.scss"

type ButtonProps = {
  label: string
  onClick?: () => void
  isSecondary?: boolean
  isSmall?: boolean
  isMailTo?: boolean
  id?: string
}

const Button: React.FC<ButtonProps> = ({
  isSecondary,
  isSmall,
  isMailTo,
  onClick,
  label,
  id,
}) => {
  return (
    <button
      id={id}
      className={isSecondary ? style.secondary : style.primary}
      style={{ fontSize: isSmall ? "0.9rem" : "1rem" }}
      onClick={() => {
        isMailTo
          ? location.assign("mailTo: info@charlesdudleygardens.com")
          : onClick && onClick()
      }}
    >
      <div />
      <span>{label}</span>
      <div />
    </button>
  )
}

export default Button
