
import lionelPic from '../assets/images/testimonials/lionel.jpg'
import loisPic from "../assets/images/testimonials/lois.jpg"
import antonPic from "../assets/images/testimonials/anton.jpeg"
import trudiePic from "../assets/images/testimonials/trudie.jpeg"
import eddiePic from "../assets/images/testimonials/eddie.jpeg"
import benPic from "../assets/images/testimonials/ben.jpeg"

export type TestimonialData = {
  name: string
  location: string
  photo: string
  text: string
}

const TESTIMONIALS: { [key: string]: TestimonialData } = {
  lionel: {
    name: "Lionel",
    location: "Ascot",
    photo: lionelPic,
    text:
      "The team at Charles Dudley did a fantastic job from start to finish with our planting project. They installed the troughs and window boxes at our office in west London with a real stress free approach. The designers gave us several options with the different types of planters/troughs we could use and the variety of plant schemes which was very useful in helping us get what we wanted. I highly recommend Charles Dudley for their innovative ideas and proactive approach.",
  },

  lois: {
    name: "Lois",
    location: "Streatham Hill",
    photo: loisPic,
    text:
      "Charlie and his team have transformed our garden in South London. It was unruly and overgrown which meant we couldn't enjoy the space. Charlie and his team worked extremely hard and got a lot done in a short amount of time. Particularly pleased with our new lawn which all my family are jealous of."
  },
  anton: {
    name: "Anton",
    location: "Windsor",
    photo: antonPic,
    text:"After a consultation with Charlie we went ahead with his quote.\n\n We were amazed with the extra effort that his team put in. Polite, punctual and above all tidy, they transformed our gardens for summer and hopefully winter as well."
  },
  trudie: {
    name: "Trudie",
    location: "Bletchingley, Surrey",
    photo: trudiePic,
    text:
      "Charles Dudley Gardens installed this Parterre after a garden design consultation. The way in which the job was conducted - these guys are very attentive and hugely inciteful with their ideas. The shapes are lovely! I could not of hoped for anything better from start to finish they were very professional.",
  },
  eddie: {
    name: "Eddie",
    location: "Holland Park, London",
    photo: eddiePic,
    text:
      "Charlie and his guys did a great job laying a new artificial lawn for us. They worked extremely hard and were quick and efficient. They are friendly and very professional and I will certainly continue to use them in my garden.",
  },
  ben: {
    name: "Ben",
    location: "Chelsea, London",
    photo: benPic,
    text:
      "As a wealth manager I look after some of the biggest estates all over London, and since changing over to Charles Dudley, this company are the best we’ve used yet to maintain our gardens. They are quick, reliable and the attention to detail is incredible. They are yet to prove us otherwise and I couldn’t recommend them highly enough.",
  },
}

export default TESTIMONIALS
