import React, { useRef, useEffect, useState } from "react"
import gsap from "gsap"
import SplitText from "gsap/SplitText"
import { Service } from "../../resources/services"
import styles from "./ServiceBlock.module.scss"

type ServiceBlockProps = {
  data: Service
  index: number
}

const ServiceBlock: React.FC<ServiceBlockProps> = ({ data, index }) => {
  const containerId = `service-block-${index}`
  const imgId = `service-img-${index}`
  const descId = `service-desc-${index}`
  // Heading Ids
  const headingLineId = `service-heading-line-${index}`
  const headingDivId = `service-heading-div-${index}`
  const headingTagId = `service-heading-tag-${index}`
  // description ids
  const descriptionHeadingId = `service-desc-heading-${index}`
  const descriptionParagraphId = `service-desc-p-${index}`

  const [showTag, setShowTag] = useState(false)

  const startsOnRight = index % 2
  const headingClass = startsOnRight ? styles.headingRight : styles.headingLeft
  const imgClass = startsOnRight ? styles.imageRight : styles.imageLeft
  const descClass = startsOnRight ? styles.descLeft : styles.descRight

  // const textSplit = useRef<SplitText>(null)

  useEffect(() => {
    const textSplit = new SplitText(`#${descriptionHeadingId}`)

    gsap.set([textSplit.lines, `#${descriptionParagraphId}`], {
      opacity: 0,
      x: startsOnRight ? 32 : -32,
    })

    gsap
      .timeline({
        defaults: {
          ease: "none",
        },
        scrollTrigger: {
          trigger: `#${containerId}`,
          start: "top bottom",
          end: "center top",
          scrub: 0.5,
        },
      })
      // Heading
      .fromTo(
        `#${headingLineId}`,
        { xPercent: startsOnRight ? 100 : -100, opacity: 1 },
        { duration: 1, xPercent: 0, opacity: 0.2 }
      )
      .fromTo(
        `#${headingDivId}`,
        { yPercent: 180, rotate: startsOnRight ? -4 : 4 },
        { duration: 0.5, yPercent: 0, rotate: 0 },
        "0.5"
      )
      .call(() => setShowTag(prev => !prev))
      // Image
      .fromTo(
        `#${imgId}`,
        { opacity: 0, scale: 1.4 },
        { duration: 1, opacity: 1, scale: 1, ease: "ease-in" },
        0
      )
      // Description
      .to(`#${descId}`, { duration: 2, yPercent: -50 })

    gsap.to([textSplit.lines, `#${descriptionParagraphId}`], {
      opacity: 1,
      x: 0,
      duration: 1,
      delay: 1,
      stagger: 0.2,
      scrollTrigger: {
        trigger: `#${descId}`,
        toggleActions: "play none none reset",
      },
    })

    // ScrollTrigger.addEventListener("refreshInit", () => gsap.set(".box", {y: 0}));
  }, [])

  useEffect(() => {
    if (!showTag) {
      gsap.to(`#${headingTagId}`, { yPercent: -100, duration: 0.3 })
    } else {
      gsap.to(`#${headingTagId}`, { yPercent: 0, duration: 0.4 })
    }
  }, [showTag])

  return (
    <div id={containerId} className={styles.content}>
      {/* Heading */}
      <div className={headingClass}>
        <h3>
          <div id={headingDivId}>{data.title}</div>
        </h3>
        <div id={headingLineId} />
        <span>
          <span id={headingTagId}>{data.subtitle}</span>
        </span>
      </div>
      {/* Image */}
      <div className={imgClass}>
        <img id={imgId} src={data.image} alt={data.title} />
      </div>
      {/* Description contains heading and paragraph */}
      <div id={descId} className={descClass}>
        <h4 id={descriptionHeadingId}>{data.heading}</h4>
        <p id={descriptionParagraphId}>{data.description}</p>
        <div className={styles.line} />
        <div className={styles.line} />
        <div className={styles.line} />
      </div>
    </div>
  )
}

export default ServiceBlock
