import maintenanceImg from "../assets/images/service-maintenance.jpg"
import designImg from "../assets/images/service-design.jpg"
import installationImg from "../assets/images/service-installation.jpg"
import potsImg from "../assets/images/service-pots.jpg"

export type Service = {
  title: string
  subtitle: string
  heading?: string
  description: string
  image: string
  deliverables?: string[]
}

export const SERVICES_DATA: Service[] = [
  {
    title: "Garden Maintenance",
    subtitle: "Premium Service",
    heading:
      "Charles Dudley gained experienced maintaining some of the UK’s finest gardens.",
    description:
      "We offer competetive rates and value great communication and team reliability. If you’re looking for a team to help your gardens flourish, we’d love to work with you.",
    image: maintenanceImg,
  },
  {
    title: "Garden Design",
    subtitle: "High End Service",
    heading:
      "Garden design is about understanding your character and vision for the space.",
    description:
      "We use our extensive plant knowledge to create a solution that meets your budget and maintenance requirements.",
    image: designImg,
  },
  {
    title: "Garden Installation",
    subtitle: "With Landscaping",
    heading:
      "Highly skilled landscape and maintenance teams can install any garden to your bespoke needs.",
    description:
      "Our experienced garden design team can build upon your design vision, installing the garden you’ve always dreamed of. Having worked in some of the most prestigious establishments in London, we know how to install gardens at the highest level.",
    image: installationImg,
    // deliverables: [
    //   "Turfing",
    //   "Artificial Lawns",
    //   "Fencing and Trellis Installation",
    //   "Lighting",
    //   "Water Features",
    //   "Planting",
    //   "Trough / Planter and Window Box Installation",
    // ],
  },

  // {
  //   title: "Pot and Plant Installation",
  //   description:
  //     "Charles Dudley are in partnership with the Plotted Plant Co, a garden design and planting design service for internal and external spaces. Together we can bring new life into your amazing interior or exterior. Contact us today to book a consultation.",
  //   image: potsImg,
  // },
]

// const servicesData = [
//   {
//     heading: "maintain world class gardens.",
//     body:
//       "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc pellentesque suspendisse velit a fusce vel feugiat enim. Nam non sed odio sed nisl, ornare ac, quis orci. Ultrices malesuada netus aliquet vitae ut sollicitudin leo.",
//   },
//   {
//     heading: "install premium quality gardens.",
//     body:
//       "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc pellentesque suspendisse velit a fusce vel feugiat enim. Nam non sed odio sed nisl, ornare ac, quis orci. Ultrices malesuada netus aliquet vitae ut sollicitudin leo.",
//   },
//   {
//     heading: "design an impressive new garden.",
//     body:
//       "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc pellentesque suspendisse velit a fusce vel feugiat enim. Nam non sed odio sed nisl, ornare ac, quis orci. Ultrices malesuada netus aliquet vitae ut sollicitudin leo.",
//   },
// ]
