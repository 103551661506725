import React, {
  useRef,
  useState,
  useEffect,
  useMemo,
  useContext,
  RefObject,
} from "react"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
// import shortid from "shortid"
import styles from "./LinesHeading.module.scss"
import { LoadingContext } from "../../pages"

gsap.registerPlugin(ScrollTrigger)

export enum HEADING_TAG {
  "h1",
  "h2",
  "h3",
}

export enum HEADING_ALIGN {
  "left",
  "center",
  "right",
}

type LinesHeadingProps = {
  tag: HEADING_TAG
  align?: HEADING_ALIGN
  lines: string[]
  addScrollTrigger: boolean
  isDark?: boolean
  hide?: boolean
}

const LinesHeading: React.FC<LinesHeadingProps> = ({
  tag,
  lines,
  addScrollTrigger,
  isDark,
  hide,
}) => {
  const isLoading = useContext(LoadingContext)

  const heading = useRef<HTMLHeadingElement>(null)
  const [spanRefs, setSpanRefs] = useState(lines.map(() => React.createRef()))
  const [lineRefs, setLineRefs] = useState(lines.map(() => React.createRef()))

  useEffect(() => {
    setOut()
  }, [])

  useEffect(() => {
    if (hide) setOut()
  }, [hide])

  useEffect(() => {
    if (isLoading) return
    // Show immediately or set trigger using scroll
    if (addScrollTrigger) {
      ScrollTrigger.create({
        trigger: heading.current as HTMLHeadingElement,
        start: "bottom bottom-=100px",
        // end: "bottom top",
        onEnter: transitionIn,
        onLeaveBack: setOut,
        once: true,
        // onEnterBack:,,
        // onLeave: setOut,
      })
    } else if (!hide) {
      transitionIn()
    }
  }, [isLoading, hide])

  const setOut = () => {
    // Sets the initial transforms
    // Moves the text down and invisible
    gsap.set(
      spanRefs.map(ref => ref.current),
      {
        rotate: (i: number) => (i % 2 ? 4 : -4),
        yPercent: 100,
        opacity: 0,
      }
    )
    // Scales the underline to 0
    gsap.set(
      lineRefs.map(ref => ref.current),
      {
        opacity: 0.5,
        scaleX: 0,
      }
    )
  }

  const transitionIn = () => {
    gsap.to(
      lineRefs.map(ref => ref.current),
      {
        duration: 0.4,
        scaleX: 1,
        opacity: 1,
        stagger: -0.15,
        onComplete: () => {
          gsap.to(
            spanRefs.map(ref => ref.current),
            {
              duration: 0.4,
              opacity: 1,
              stagger: -0.15,
              yPercent: 0,
              rotate: 0,
            }
          )
        },
      }
    )
  }

  const returnContent = useMemo(
    () =>
      lines.map((text: string, i: number) => (
        <div key={text}>
          <div
            ref={lineRefs[i] as RefObject<HTMLDivElement>}
            className={styles.underline}
          />
          <span
            ref={spanRefs[i] as RefObject<HTMLElement>}
            className={styles.span}
          >
            {text}
          </span>
        </div>
      )),
    [lines]
  )

  const className = `${styles.heading} ${isDark ? styles.dark : undefined}`

  return tag === HEADING_TAG.h1 ? (
    <h1 className={className} ref={heading}>
      {returnContent}
    </h1>
  ) : (
    <h2 className={className} ref={heading}>
      {returnContent}
    </h2>
  )
}

export default LinesHeading
