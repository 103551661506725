import React from "react"
import LinesHeading, { HEADING_TAG } from "../linesHeading/LinesHeading"
import Button from "../button/Button"

import styles from "./Header.module.scss"

const Header: React.FC = () => (
  <header id="header" className={styles.header}>
    <LinesHeading
      tag={HEADING_TAG.h1}
      lines={["Showcase Your", "Exquisite Gardens"]}
      addScrollTrigger={false}
    />
    <div>
      <p id="header-p">
        Charles Dudley offer high-end garden services to commercial and domestic
        sites across London. We help our partners create and maintain beautiful
        spaces.
      </p>
    </div>
    <Button id="header-button" label="Book a Consultation" isMailTo={true} />
  </header>
)

export default Header
