import ore1Img from "../assets/images/ore1.jpg"
import hp1Img from "../assets/images/hp1.jpg"
import ore2Img from "../assets/images/ore2.jpg"
import hollandImg from "../assets/images/service-installation.jpg"

// export type BackgroundImageData = {
//   caption: string
//   imageSrc: string
// }
// Number of images must match the background grid
export const PORTFOLIO_DATA = [
  {
    caption: "The Old Rectory Estate, Surrey",
    services: ["Design", "Installation", "Maintenance"],
    image: ore1Img,
  },
  {
    caption: "Barnes, London",
    services: ["Installation", "Maintenance"],
    image: hp1Img,
  },
  {
    caption: "The Old Rectory Estate, Surrey",
    services: ["Design", "Installation", "Maintenance"],
    image: ore2Img,
  },
  {
    caption: "Holland Park, London",
    services: ["Design", "Installation", "Maintenance"],
    image: hollandImg,
  },
]

export default PORTFOLIO_DATA
