import React, { useEffect } from "react"
import Button from "../button/Button"
import gsap from "gsap"
import charliePic from "../../assets/images/charlie.jpg"

import styles from "./About.module.scss"

const About: React.FC = () => {
  useEffect(() => {
    gsap
      .timeline({
        defaults: {
          ease: "none",
        },
        scrollTrigger: {
          trigger: `#about-container`,
          start: "top bottom",
          end: "center top",
          scrub: 0.5,
        },
      })
      .fromTo(
        `#about-heading-line`,
        { scaleX: 0, opacity: 1 },
        { duration: 1, scaleX: 1, opacity: 0.2 }
      )
      .fromTo(
        `#about-heading-div`,
        { yPercent: 180 },
        { duration: 0.5, yPercent: 0 },
        "0.5"
      )
      .to("#about-content", { duration: 2, yPercent: -50 }, 0)
    // .fromTo(`#about-photo`, { opacity: 0 }, { duration: 2, opacity: 1 })
  }, [])

  return (
    <section id="about-container" className={styles.container}>
      {/* Heading */}
      <div className={styles.heading}>
        <h3>
          <div id="about-heading-div">About Us</div>
        </h3>
        <div id="about-heading-line" />
      </div>

      {/* Photo and Text */}
      <div className={styles.photo}>
        <img
          id="about-photo"
          src={charliePic}
          alt="Managing Director, Charles Procter"
        />
      </div>

      <div id="about-content" className={styles.content}>
        <div className={styles.line} />
        <div className={styles.line} />

        <h4>We’ve sharpened our saws in some of London’s greatest gardens.</h4>
        <p>
          At Charles Dudley Gardens we’re obsessed with the finer details. To
          us, attention to detail is one of the most defining aspects of a great
          garden, and we provide it to all our clients.
        </p>
        <Button
          label="Start a Conversation"
          isMailTo={true}
          isSecondary={true}
        />
      </div>
    </section>
  )
}

export default About

{
  /* <header>

</header> */
}

{
  /* Make Fullscreen overlay */
}
{
  /* {showText && (
            <p>
              We’re passionate about customer service and reliability too. From
              start to finish we’ll make the process easy for you. Whether it be
              garden design, garden maintenance or simply an interior pot and
              plant installation, every stage is handled with great
              communication and efficiency.
              <br />
              <br />
              We’re experts on working on internal and external spaces, whether
              it be eye grabbing planters to artificial lawns; seasonal designed
              window boxes to contemporary courtyards, and roof terraces to
              water gardens, we’ve installed all there is to make a space
              stunning.
              <br />
              <br />
              Having worked with some of the most prestigious clientele in
              London, we’re used to maintaining that high standard of service.
            </p>
          )} */
}
