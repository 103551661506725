import React, { useEffect, useLayoutEffect, useState } from "react"
import { PageProps } from "gatsby"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { SplitText } from "gsap/SplitText"
import imagesLoaded from "imagesloaded"

import logo from "../assets/brand/logo.svg"

import Layout from "../hoc/Layout"

import SEO from "../components/seo"
import ServiceBlock from "../components/services/ServiceBlock"
import Header from "../components/header/Header"
import { SERVICES_DATA, Service } from "../resources/services"

// import benefitIcon1 from "../assets/benefits/1.svg"
// import benefitIcon2 from "../assets/benefits/2.svg"
// import benefitIcon3 from "../assets/benefits/3.svg"

import MarqueeText from "../components/marqueeText/MarqueeText"
import TestimonialsCarousel from "../components/testimonials/carousel/TestimonialsCarousel"
import Portfolio from "../components/portfolio/Portfolio"
import About from "../components/about/About"

import bgImage from "../assets/images/background.jpg"

import styles from "./index.module.scss"

gsap.registerPlugin(ScrollTrigger)

// type BenefitData = {
//   heading: string
//   text: string
//   icon: any
// }

// const BENEFITS_DATA: BenefitData[] = [
//   {
//     heading: "Quality",
//     text: "Our team is passionate about gardening at the highest level.",
//     icon: benefitIcon1,
//   },
//   {
//     heading: "Selection",
//     text:
//       "With extensive knowledge we can offer you the best selection of plants.",
//     icon: benefitIcon2,
//   },
//   {
//     heading: "Service",
//     text: "We pride ourselves on excellent commuication and reliability.",
//     icon: benefitIcon3,
//   },
// ]

export const LoadingContext = React.createContext(false)

const IndexPage: React.FC<PageProps> = ({ data }) => {
  const [enablePortfolio, setEnablePortfolio] = useState(false)

  const [isLoading, setIsLoading] = useState(true)

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
    // Locks scroll on mount
    document.width = window.innerWidth
    document.height = window.innerHeight
    document.body.style.overflow = "hidden"
    document.body.scroll = "no"
    // Load time
    // console.time("load")
    // window.addEventListener("load", onPageLoad)
    // imagesloaded('#main', { background: true }, onImagesLoad);
    let imgLoad = imagesLoaded("#main")
    imgLoad.on("always", function () {
      console.log(imgLoad.images.length + " images loaded")
      // console.timeEnd("load")
      onImagesLoad()
    })
  }, [])

  // ------- Intial Presentation ---------
  const onImagesLoad = () => {
    // Set up the header transition-in
    const splitP = new SplitText("#header-p", { type: "lines" })

    gsap.to("#loading-brand", {
      opacity: 0,
      onComplete: () => {
        setIsLoading(false)
        document.body.style.overflow = "scroll"
        document.body.scroll = "yes"
        gsap
          .timeline()
          .fromTo(
            "#portfolio-image",
            { opacity: 0 },
            { opacity: 0.33, duration: 2 }
          )
          // Add Heading transition here
          .fromTo(
            [splitP.lines, "#header-button"],
            { opacity: 0, x: i => (i % 2 ? -16 : 16) },
            { duration: 1, opacity: 1, x: 0, stagger: 0.2 }
          )
          .to("#nav-logo", { y: 0, duration: 0.6 })
      },
    })
  }

  useEffect(() => {
    // ------- Portfolio and Header ---------
    gsap
      .timeline({
        defaults: { ease: "none" },
        scrollTrigger: {
          // markers: true,
          trigger: "#header",
          start: "top top",
          end: "bottom top",
          scrub: true,
          pin: "#portfolio",
        },
      })
      .to("#header", { duration: 0.5, autoAlpha: 0 }, 0)
      .fromTo(
        "#portfolio-image",
        { opacity: 0.33 },
        { duration: 1, opacity: 1 },
        0
      )
      .to("#portfolio-carousel", { duration: 1.5, scale: 1 }, 0)
      .call(() => setEnablePortfolio(prev => !prev))
      // Adds a slight delay before unpinning the portfolio - Investigate .addPause
      .set({}, {}, "+=0.25")
  }, [])

  return (
    <LoadingContext.Provider value={isLoading}>
      <SEO title="Garden Maintenance, Garden Design and Installation in London and Surrey" />
      {/* ------------- Loading Indicator ------------- */}
      {isLoading && (
        <div className={styles.loading}>
          <div id="loading-brand">
            <img src={logo} alt="Charles Dudley" />
            <div />
          </div>
          <span>Loading...</span>
        </div>
      )}

      <Layout>
        {/* ------------- Portfolio ------------- */}
        <div id="portfolio" className={styles.portfolio}>
          <Portfolio isEnabled={enablePortfolio} />
        </div>

        {/* ------------- Header ------------- */}
        <Header />

        {/* ------------- Portfolio Spacer ------------- */}
        <div
          id="portfolio-trigger"
          style={{
            position: "relative",
            height: "100vh",
            width: "100vw",
            zIndex: -1,
            pointerEvents: "none",
          }}
        />

        {/* ------------- Fixed Image Background ------------- */}
        <div className={styles.background}>
          <img src={bgImage} alt="background" />
        </div>

        {/* ------------- Quote ------------- */}
        <div className={styles.quote}>
          <MarqueeText text="All gardens are a form of autobiography." />
        </div>

        {/* ------------- Services ------------- */}
        <section className={styles.servicesSection}>
          <h4 className="left large"></h4>
          {Object.values(SERVICES_DATA).map((data: Service, i: number) => (
            <ServiceBlock key={data.title} data={data} index={i} />
          ))}
        </section>

        {/* ------------- About ------------- */}
        <About />

        {/* ------------- Testimonials ------------- */}
        <TestimonialsCarousel />
      </Layout>
    </LoadingContext.Provider>
  )
}

export default IndexPage

// export const homeQuery = graphql`
//   query LandingPage {
//     image1: file(relativePath: { eq: "ore1.jpg" }) {
//       childImageSharp {
//         fluid(maxWidth: 1200, quality: 80) {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//   }
// `

{
  /* ------------- Benefits ------------- */
}
//   <section className={styles.benefitsSection} id="benefits-section">
//   <GridContainer
//     id="benefits-container"
//     gridClass={styles.benefitsGrid}
//   >
//     <div className={styles.benefitBoxes}>
//       {BENEFITS_DATA.map((benefit, i) => (
//         <div key={i} className={`${styles.benefitBox} benefit-box`}>
//           <img src={benefit.icon} alt={benefit.heading} />
//           <div>
//             <h3>{benefit.heading}</h3>
//             <p>{benefit.text}</p>
//           </div>
//         </div>
//       ))}
//     </div>
//   </GridContainer>
// </section>
