import React, { useEffect, useRef, useCallback, useState } from "react"
import styles from "./Portfolio.module.scss"
import LinesHeading, { HEADING_TAG } from "../linesHeading/LinesHeading"
// import GridContainer from "../../hoc/gridContainer/GridContainer"
import arrowIcon from "../../assets/icons/arrow-teal20.svg"
import { LoadingContext } from "../../pages"
import { useCarousel } from "../../hooks/useCarousel"
import PORTFOLIO_DATA from "../../resources/portfolio"

type ServiceTagProps = {
  text: string
}

const ServiceTag: React.FC<ServiceTagProps> = ({ text }) => (
  <div className={styles.serviceTag}>
    <span>{text}</span>
  </div>
)

// -----------

export type FluidImage = {
  aspectRatio: number
  base64: string
  sizes: string
  src: string
  srcSet: string
}

type PortfolioProps = {
  isEnabled: boolean
}

const Portfolio: React.FC<PortfolioProps> = ({
  isEnabled,
  // onImageLoad,
}) => {
  const { index, offset, next, back, handlers, Carousel } = useCarousel(
    PORTFOLIO_DATA.length
  )

  const onControlPress = useCallback((isLeft: boolean) => {
    isLeft ? back() : next()
  }, [])

  return (
    <div
      className={styles.container}
      {...handlers}
      style={{ pointerEvents: isEnabled ? "all" : "none" }} //Prevents side scrolling until correct point
    >
      <LinesHeading
        tag={HEADING_TAG.h2}
        lines={["Trusted by London's Finest"]}
        addScrollTrigger={false}
        isDark={true}
        hide={!isEnabled}
      />

      <div className={styles.main} id="portfolio-carousel">
        <Carousel index={index} offset={offset} slideClass={styles.slide}>
          {PORTFOLIO_DATA.map(data => (
            <div
              key={data.caption}
              id="portfolio-image-container"
              className={styles.portfolioImgContainer}
            >
              <img id="portfolio-image" src={data.image} alt={data.caption} />
            </div>
          ))}
        </Carousel>
        {/* Beneath the Image */}
        <div className={styles.info} style={{ opacity: isEnabled ? 1 : 0 }}>
          <span>{PORTFOLIO_DATA[index].caption}</span>
          <div className={styles.services}>
            {PORTFOLIO_DATA[index].services.map((service: string) => (
              <ServiceTag key={service} text={service} />
            ))}
          </div>
        </div>
        {/* Left/Right Controls to either side */}
        {isEnabled && (
          <>
            <img
              role="button"
              title="previous"
              style={{ visibility: index === 0 ? "hidden" : "visible" }}
              src={arrowIcon}
              className={styles.control}
              onClick={() => onControlPress(true)}
            />
            <img
              role="button"
              title="next"
              style={{
                visibility:
                  index === PORTFOLIO_DATA.length - 1 ? "hidden" : "visible",
              }}
              src={arrowIcon}
              className={styles.control}
              onClick={() => onControlPress(false)}
            />
          </>
        )}
      </div>
    </div>
  )
}

export default Portfolio
