import React from "react"
import styles from "./Testimonial.module.scss"

type TestimonialProps = {
  text: string
  name: string
  location: string
  isActive: boolean
  photo: string
}

const Testimonial = ({
  text,
  name,
  photo,
  location,
  isActive,
}: TestimonialProps) => (
  <div
    className={styles.container}
    key={name}
    style={{ opacity: isActive ? 1 : 0.3 }}
  >
    {/* Photo and Name */}
    <div className={styles.photo}>
      <div>
        <img
          className={styles.photo}
          src={photo}
          alt={`${name} Profile`}
          loading="lazy"
        />
      </div>
      <div>
        <span>{name}</span>
      </div>
    </div>
    {/* Quote and Location */}
    <div className={styles.quote}>
      <div>
        <p>{text}</p>
      </div>
      <div>
        <span>{location}</span>
      </div>
    </div>
  </div>
)

export default Testimonial
