import React, { useState, useCallback } from "react"
import { useSwipeable } from "react-swipeable"

const useCarousel = (length: number) => {
  const [offset, setOffset] = useState<number>(0)
  const [index, setIndex] = useState<number>(0)

  const next = () => setIndex(idx => (idx + 1) % length)
  const back = () =>
    setIndex(idx => (idx === 0 ? length - 1 : (idx - 1) % length))

  const handlers = useSwipeable({
    onSwiping(e) {
      setOffset(-e.deltaX)
    },
    onSwipedLeft(e) {
      setOffset(0)
      next()
    },
    onSwipedRight(e) {
      setOffset(0)
      back()
    },
    trackMouse: true,
    trackTouch: true,
  })

  return { index, offset, next, back, handlers, Carousel }
}

// Wrap the container to achieve less than full-width slides.

const CONTAINER_STYLE: React.CSSProperties = {
  direction: "ltr",
  willChange: "transform",
  display: "flex",
  alignItems: "flex-end",
  width: "100%",
  maxWidth: "100%",
  cursor: "grab",
}

const SLIDE_STYLE: React.CSSProperties = {
  width: "100%",
  flexShrink: 0,
  boxSizing: "border-box",
  padding: "0 24px",
}

type CarouselProps = {
  children: React.ReactNode
  index: number
  offset: number
  slideClass?: string
}

const Carousel: React.FC<CarouselProps> = ({
  children,
  index,
  offset,
  slideClass,
}) => (
  <div
    style={{
      ...CONTAINER_STYLE,
      transition: offset
        ? "none"
        : "transform 0.4s cubic-bezier(0.15, 0.3, 0.25, 1) 0s, height 0.4s cubic-bezier(0.15, 0.3, 0.25, 1) 0s",
      transform: `translate(calc(${-100 * index}% + ${offset}px), 0px)`,
    }}
    children={React.Children.map(children, (child, index) => (
      <div key={index} className={slideClass} style={SLIDE_STYLE}>
        {child}
      </div>
    ))}
  />
)

export { Carousel, useCarousel }
