import React, { useEffect } from "react"
import { useCarousel } from "../../../hooks/useCarousel"

import Testimonial from "../testimonial/Testimonial"
import TESTIMONIALS, { TestimonialData } from "../../../resources/testimonials"

import styles from "./TestimonialsCarousel.module.scss"

const INTERVAL = 20000
const DATA = Object.values(TESTIMONIALS)
const LENGTH = DATA.length

const TestimonialsCarousel = () => {
  const { index, offset, next, back, handlers, Carousel } = useCarousel(LENGTH)

  useEffect(() => {
    const interval = setInterval(next, INTERVAL)
    return () => clearInterval(interval)
  }, [])

  return (
    <section className={styles.container}>
      <div className={styles.carouselWrapper} {...handlers}>
        <Carousel index={index} offset={offset}>
          {DATA.map((slide: TestimonialData, i: number) => (
            <Testimonial
              key={slide.name}
              text={slide.text}
              name={slide.name}
              location={slide.location}
              photo={slide.photo}
              isActive={i === index}
            />
          ))}
        </Carousel>
      </div>
    </section>
  )
}

export default TestimonialsCarousel
